// import logo from './logo.svg';
// import './App.css';
import React from 'react';
import { useState } from 'react';

import Container from '@material-ui/core/Container';
import { Button } from '@material-ui/core';
import { Box } from '@material-ui/core';

const allMovies = require('./movies.json').movies

function Card({ children }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height="100vh"
      alignItems="center"
      textAlign="center"
    >
      {children}
    </Box>
  )
}

function MoviePicker({ movies, pickMovie, next }) {
    return (
      <Card>
        <h1>Pick Movie</h1>
        { movies.map((movie, index) => <Button key={index} onClick={pickMovie(movie)} style={{color:'white'}}>{movie.movie} ({movie.year})</Button>)}
        <Button onClick={next} style={{color:'white'}}>More...</Button>
      </Card>
    )
}

function Movie({ movie, next }) {
  const [reveal, setReveal] = useState(false)

  return (
    <Card>
      <h1>{movie.movie} ({movie.year})</h1>
      
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="55px" 
      >
        {!reveal && 
          <Button onClick={() => setReveal(true)} style={{color:'white'}}>Reveal</Button>
        }
        {reveal && <h2>{movie.audience}%</h2>}
      </Box>
      
      <Button onClick={next} style={{color:'white'}}>Next</Button>
    </Card>
  )
}

function Play({ pickMovies }) {
  return <Card>
      <h1>🥝 Bad Fruit</h1>
      <Button onClick={pickMovies}>Play</Button>
      <Box style={{position: "absolute", bottom: "0px"}}><small>This product uses the TMDB API but is not endorsed or certified by TMDB.</small><br/><a href="https://www.themoviedb.org/"><img src="./tmdb.svg" alt="tmdb" width="25%"/></a></Box>
    </Card>
}

function App() {

  const [play, setPlay] = useState(false)

  const [movies, setMovies] = useState([])

  const [movie, setMovie] = useState()

  const [backgroundStyle, setBackgroundStyle] = useState({})

  const pick5 = () => {
    const movies = [...allMovies]

    // Shuffle Movies array using diffie-hellman algorithm
    for (let i = movies.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [movies[i], movies[j]] = [movies[j], movies[i]];
    } 

    // Pick 5 movies
    return movies.slice(0, 5)
  }

  const pickMovies = () => {
    setPlay(true)
    setMovie(undefined)

    let posterURL = ''
    while (true) {
      const movie = allMovies[Math.floor(Math.random() * allMovies.length)]
      
      if (movie.posterURL) {
        posterURL = movie.posterURL
        break;
      }
    }

    setBackgroundStyle({
      backgroundImage: `linear-gradient(to bottom, rgba(58 58 58 / 50%), rgba(44 44 44 / 100%)), url(${posterURL})`,
      backgroundSize: '500%',
      backgroundRepeat: 'no-repeat',
      color: 'white'
    })

    // Pick 5 movies
    const picked = pick5()

    setMovies(picked)
  }

  const pickMovie = (movie) => () => {
    setBackgroundStyle({
      backgroundImage: `linear-gradient(to bottom, rgba(58 58 58 / 50%), rgba(44 44 44 / 100%)), url(${movie.posterURL})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      color: 'white'
    })

    setMovie(movie)
  }

  return (
    <div className="App" style={backgroundStyle}>
      <Container maxWidth='xl'>
        <Box
          height="100vh"
          style={{backdropFilter: 'blur(5px)'}}
        >

           {/* <Box> */}
            {!play && (<Play pickMovies={pickMovies}/>)}

            {play && (
              <>
                {!movie && <MoviePicker movies={movies} pickMovie={pickMovie} next={pickMovies}/>}
            
                {movie && <Movie movie={movie} next={pickMovies}/>}
              </>
            )}
          {/* </Box> */}
        </Box>
      </Container>
    </div>
  );
}

export default App;
